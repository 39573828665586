import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  Avatar,
  TableRow,
  Paper,
  Typography,
} from '@material-ui/core';
import { DateFormatUtil, formatMinutesToHoursPeriod } from '@/utils';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import { blurSelector, userSelector } from '@/redux';
import { userRoles } from '@/constants';
import { IReportsTableProps } from './types';
import useStyles from './styles';

export interface IReportTableHeadCell {
  name: string;
  align: 'left' | 'right' | 'inherit' | 'center' | 'justify' | undefined;
  numeric: boolean;
  id: string;
  allowedForRoles?: string[];
}

const tableHead = [
  { name: 'User', align: 'center', numeric: true, id: 'user' },
  { name: 'Date', align: 'left', numeric: false, id: 'createdAt' },
  { name: 'Time', align: 'left', numeric: false, id: 'time' },
  { name: 'Rate', align: 'left', numeric: false, id: 'rate', allowedForRoles: [userRoles.admin] },
  { name: 'Salary', align: 'left', numeric: false, id: 'salary' },
  { name: 'Project', align: 'left', numeric: false, id: 'project' },
  { name: 'Task', align: 'left', numeric: false, id: 'task' },
  { name: 'Description', align: 'left', numeric: false, id: 'description' },
] as IReportTableHeadCell[];
export default function ReportTable({ reports, user }: IReportsTableProps): JSX.Element {
  const classes = useStyles();
  const showBlur = useSelector(blurSelector);
  const authorizedUser = useSelector(userSelector);

  return (
    <TableContainer component={Paper} className={classes.tableWrap}>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            {tableHead.map((el) =>
              el.allowedForRoles?.length &&
              !el.allowedForRoles.includes(authorizedUser?.role ?? '') ? null : (
                <TableCell key={el.id} align={el.align} className={classes.tableHeader}>
                  {el.name}
                </TableCell>
              ),
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {reports &&
            reports.map((report) => (
              <TableRow key={report?.id}>
                <TableCell key={report.id} scope="row" className={classes.tableItem}>
                  <div className={classes.userBlock}>
                    <Avatar
                      alt={user?.fullName}
                      src={report.photo ?? '/src/assets/svg/logo.svg'}
                      className={classes.tableRowAvatar}
                    />
                    {user?.fullName}
                  </div>
                </TableCell>
                <TableCell className={classes.tableItem}>
                  {DateFormatUtil.formatDate(report.workedDay)}
                </TableCell>
                <TableCell className={classes.tableItem}>
                  {formatMinutesToHoursPeriod(report.workedMinutes)}
                </TableCell>
                {authorizedUser?.role === userRoles.admin && (
                  <TableCell className={clsx(classes.tableItem, { [classes.blurred]: showBlur })}>
                    {Number(report.rate).toFixed(2)}
                  </TableCell>
                )}
                <TableCell className={clsx(classes.tableItem, { [classes.blurred]: showBlur })}>
                  {Number(report.salary).toFixed(2)}
                </TableCell>
                <TableCell className={classes.tableItem}>
                  {report.taskType === 'Vacation' ? 'Vacation' : report.project?.projectName}
                </TableCell>
                <TableCell className={classes.tableItem}>
                  <Typography className={clsx(classes.task, classes.bugFixing)}>
                    {report.taskType}
                  </Typography>
                </TableCell>
                <TableCell className={classes.tableItem}>{report.description}</TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
