import { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormikHelpers, useFormik } from 'formik';
import {
  ICreateUserReport,
  userProjectsSelector,
  loadUserProjects,
  IReport,
  deleteReport,
  reportsLoadingSelector,
} from '@/redux';
import { reportSchema } from '@/validations';

const useReportForm = (
  report: IReport | undefined,
  onSubmitHandler: (data: ICreateUserReport, resetForm: FormikHelpers<ICreateUserReport>) => void,
  onSuccessDelete: () => void,
  setReport?: (data: IReport | undefined) => void,
  handleClose?: () => void,
) => {
  const dispatch = useDispatch();

  const reportsLoading = useSelector(reportsLoadingSelector);

  useEffect(() => {
    dispatch(loadUserProjects());
  }, [dispatch]);

  useEffect(() => {
    setReport?.(report);
  }, [dispatch, report, setReport]);

  const deleteReportHandler = useCallback(
    (id: string) => {
      handleClose?.();
      dispatch(deleteReport({ id, onSuccess: onSuccessDelete }));
    },
    [dispatch, handleClose, onSuccessDelete],
  );

  const projects = useSelector(userProjectsSelector);

  const num = report?.workedMinutes;
  const hours = (num ?? 0) / 60;
  const formatedHours = Math.floor(hours);
  const minutes = (hours - formatedHours) * 60;
  const formatedMinutes = Math.round(minutes);

  const { handleSubmit, handleChange, handleBlur, values, touched, errors, setFieldValue } =
    useFormik<ICreateUserReport>({
      validationSchema: reportSchema,
      onSubmit: onSubmitHandler,
      initialValues: {
        id: report?.id ?? '',
        project: report?.project?.id ?? '',
        description: report?.description ?? '',
        taskType: report?.taskType ?? '',
        workerdHours: formatedHours ?? 0,
        workedMinutes: formatedMinutes ?? 0,
      },
    });
  return {
    handleSubmit,
    handleChange,
    handleBlur,
    values,
    touched,
    errors,
    projects,
    setFieldValue,
    deleteReportHandler,
    reportsLoading,
  };
};

export default useReportForm;
