import { useState, useCallback, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { format } from 'date-fns';
import DatesUtil from '@/utils/dates';
import { loadReportsByDate } from '@/redux/reports/actions';
import { ICreateUserReport, createReport, editReport, IReport, IReportStore } from '@/redux';
import { FormikHelpers } from 'formik';
import { Theme, useMediaQuery } from '@material-ui/core';
import { RangeModifier } from 'react-day-picker';

const formatType = 'dd MM yyyy';

const useReportsCalendar = (reports: IReportStore) => {
  const dispatch = useDispatch();
  const [reportDate, setReportDate] = useState(new Date());
  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));

  const currentReports = useMemo(() => {
    const reportsData = reports?.reports;

    return reportsData ? reportsData[format(reportDate, formatType)] : undefined;
  }, [reportDate, reports?.reports]);

  const [dayReports, setDayReports] = useState<IReport[] | undefined>(undefined);
  const [dateSelected, setDateSelected] = useState<RangeModifier>({
    from: undefined,
    to: undefined,
  });

  const reportForModal = {
    reports: Object.values(reports?.reports).flat(),
    totalHours: reports.totalHours,
    totalSalary: reports.totalSalary,
    hasNext: reports.hasNext,
    total: reports.total,
    currentPage: reports.currentPage,
  };

  const [isOpenReportModal, setIsOpenReporModal] = useState(false);
  const [isOpenReportDialog, setIsOpenReportDialog] = useState(false);
  const [report, setReport] = useState<IReport | undefined>(undefined);

  const fetchReports = useCallback(() => {
    dispatch(loadReportsByDate({ date: DatesUtil.toCalendarRange(dateSelected), limit: 100 }));
  }, [dispatch, dateSelected]);

  const handleDialogClose = useCallback(() => {
    setIsOpenReportDialog(false);
  }, []);

  const dialogHandler = useCallback((data: IReport | undefined) => {
    setReport(data);
    if (data) {
      setReportDate(new Date(data.workedDay));
    }
    setIsOpenReportDialog(true);
  }, []);

  const onCellClick = useCallback(
    (date: Date) => {
      setReportDate(date);
      setDayReports(reports?.reports ? reports?.reports[format(date, formatType)] : undefined);
      if (isDesktop) {
        setIsOpenReporModal(true);
      } else {
        setIsOpenReportDialog(true);
      }
    },
    [isDesktop, reports?.reports],
  );

  const openReportModal = (date: Date) => {
    setDayReports(undefined);
    setIsOpenReporModal(true);
    setReportDate(date);
  };

  const closeReportModal = () => {
    setIsOpenReporModal(false);
  };

  const onSubmitHandler = useCallback(
    (
      { project, description, taskType, workedMinutes, workerdHours, id }: ICreateUserReport,
      { resetForm }: FormikHelpers<ICreateUserReport>,
    ) => {
      const totalMinutes = workedMinutes + workerdHours * 60;
      const payload = {
        report: {
          project,
          description,
          taskType,
          workedDay: reportDate.toISOString(),
          workedMinutes: totalMinutes,
          id,
        },
        onSuccess: () => {
          fetchReports();
          setIsOpenReporModal(false);
          handleDialogClose();
          setReport(undefined);
          setDayReports(currentReports);
          resetForm();
        },
      };
      dispatch(report?.id ? editReport(payload) : createReport(payload));
    },
    [currentReports, dispatch, handleDialogClose, report?.id, reportDate, fetchReports],
  );

  useEffect(() => {
    fetchReports();
  }, [fetchReports]);

  return {
    reports,
    isOpenReportModal,
    onCellClick,
    setDateSelected,
    closeReportModal,
    report,
    setReport,
    onSubmitHandler,
    currentReports,
    openReportModal,
    dayReports,
    reportForModal,
    dialogHandler,
    isOpenReportDialog,
    handleDialogClose,
    isDesktop,
    fetchReports,
  };
};

export default useReportsCalendar;
